<template>
  <div>
    <header-portrate />
    <exports-print ref="exportbar" />
    
  <div>
    <bread-crumbs :items='items' />
    <tabsComp :tabs="tabs" />
    
    <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
      <v-row class="mt-10">
        <v-col
          cols="12"
          md="10"
          sm="12">
          <form autocomplete="off">
            <v-row>
              <v-col cols="12" md="2" sm="12">
                <label>{{lang.start_date}}</label>
                <b-input-group>
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                </b-input-group>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label>{{lang.end_date}}</label>
                <b-input-group>
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                  <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                </b-input-group>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label>{{lang.mobile}}</label>
                <b-input-group>
                  <b-form-input class="inborder" v-model="mobile" style="background:#c3d9ff;" />
                </b-input-group>
              </v-col>
              <v-col cols="12" md="2" sm="12" v-if="$store.state.licenseType.cars">
                <label>{{lang.plate_number}} / {{lang.vin_number}}</label>
                <b-input-group>     
                  <b-form-input class="inborder" v-model="plate_number" style="background:#c3d9ff;" />
                </b-input-group>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label>{{lang.invoiceno}}</label>
                <b-input-group>
                  <b-form-input class="inborder" v-model="invoice_number" style="background:#c3d9ff;" />
                </b-input-group>
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <label></label>
                <b-input-group style="margin-top:-5px">
                  <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getCurrentCards()">{{lang.search}}</b-button>
                </b-input-group>
              </v-col>
            </v-row>
          <b-input-group class="mt-3" style="display:none;">
            <b-form-input placeholder="Start Date" type="date" style="border:1px solid #000;border-radius: 0;" autocomplete="off" v-model="sdate"></b-form-input>
            <b-form-input placeholder="End Date"  type="date" style="border:1px solid #000" autocomplete="off" v-model="edate"></b-form-input>
            <b-form-input :placeholder="lang.mobile" v-model="mobile" style="border:1px solid #000;font-weight:bolder;color:#000"></b-form-input>
            <b-form-input :placeholder="lang.plate_number" style="border:1px solid #000;" v-if="$store.state.licenseType.cars" autocomplete="off" v-model="plate_number"></b-form-input>
            <b-form-input :placeholder="lang.invoiceno" style="border:1px solid #000" autocomplete="off" v-model="invoice_number"></b-form-input>
            <b-input-group-append style="border:1px solid #000">
              <!-- <b-button variant="light" style="background:gold;"  @click="getCurrentCards()"><i class="fas fa-search"></i></b-button> -->
              <b-button variant="light" style="background:black;color:#fff"  @click="getCurrentCards()">{{lang.search}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </form>
        </v-col>
        <!-- <v-col cols="12" md="4" sm="12" class="text-end" v-if="!this.$store.state.licenseType.isBill"> -->
        <v-col cols="12" md="2" sm="12" class="text-end pt-7">
          <b-button
              @click="addInvoiceEd()"
              v-b-toggle.add_invoice
              class="btn-sm btn"
              style="margin-top:7px;width:150px;background:transparent;border:none !important;margin-left:-7px !important;"
              id="multiply-button" 
            >
            <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add_invoice}}</div>
            
            </b-button>
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="12"
          style="display:none;"
          >
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="lang.search"
                single-line
                hide-details
            ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="md-3" :style="`direction:`+lang.dir+` ;`">
          <v-data-table
              :headers="headers"
              :items="tablerowsF"
              :search="search"
              :items-per-page="15"
              width="100%"
              id="MainTable"
              hide-default-footer
              fixed-header
          >
            <template v-slot:item="row">
              <tr>
                <td style="width:50px;text-align:center;">{{ row.item.invoice_number }}</td>
                <td :style="`border:2px solid #fff !important;width:50px;text-align:center;` + row.item._customer_type_style">{{ row.item._customer_type }}</td>
                <td :style='`text-align:center;`'>{{ row.item._invtype }}</td>
                <td class="text-center" nowrap v-if="row.item.customer_type == 1">{{ row.item.full_name }}</td>
                <td class="text-center" nowrap v-if="row.item.customer_type == 2">{{ row.item.company_name }}</td>
                <td class="text-center" nowrap v-if="row.item.customer_type == 3">{{ row.item.entity_name }}</td>
                <td class="text-center">{{ row.item.mobile }}</td>
                <td class="text-center" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo || $store.state.licenseType.showPlate">{{ row.item.plate_number }}</td>
                <td class="text-center" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo || $store.state.licenseType.parts | $store.state.licenseType.showPlate">{{ row.item.model }}</td>
                <td class="text-center" nowrap>{{ row.item.inv_date.substr(0,10) }}</td>  
                <!-- <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.total) }}</td> -->
                <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.ftotal,$store.state.licenseType.moneyComma) }}</td>
                <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.vat,$store.state.licenseType.moneyComma) }}</td>
                <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.paid,$store.state.licenseType.moneyComma) }}</td>
                <td class="text-center">{{ $convertToComa(row.item.cash,$store.state.licenseType.moneyComma) }}</td>
                <td class="text-center">{{ $convertToComa(row.item.span,$store.state.licenseType.moneyComma) }}</td>
                <td class="text-center" style="background:lightgreen !important">{{ $convertToComa(row.item.remain,$store.state.licenseType.moneyComma) }}</td>
                <td class="text-center" v-if="$store.state.group_id == 1 && $store.state.licenseType.showAddedBy">{{ row.item._created_by }}</td>
                <td :style='`border-left:1px solid #fff !important;width:100px;border:2px solid #FFF !important;text-align:center;color:#FFF !important;background:#`+row.item._statusbgColor'>{{ row.item._status }}</td>
                <td style="text-align:center;background:blue;border-bottom:2px solid #FFF !important;width:80px;border-top:2px solid #fff !important">
                  <div class="viewBTN" 
                  v-b-toggle.pview_invoice @click="getInvo(row.item)">
                  {{lang.view}}</div>
                  <!-- <v-btn style="font-size:0.2em;background:darkblue;width:100%;color:#fff;height:25px !important;margin-left:5px;" v-b-toggle.pview_invoice @click="getInvo(row.item)">{{lang.view}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></v-btn> -->
                  <!-- <v-btn v-if="row.item.status > 0" style="font-size:0.2em;background:red;width:50%;color:#FFF;height:25px !important;" @click="cancelInvocie(row.item)" v-b-toggle.view_invoice>ارجاع </v-btn> -->
                </td>
              </tr>
            </template>
            <template slot="body.append" v-if="$store.state.licenseType.showCardNums">
                    <tr class="black--text">
                        <th class="backEmpty"></th>
                        <th class="backEmpty"></th>
                        <th class="backEmpty"></th>
                        <th class="backEmpty"></th>
                        <th class="backEmpty"></th>
                        <!-- <th class="backEmpty" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo"></th>
                        <th class="backEmpty" v-if="$store.state.licenseType.cars || $store.state.licenseType.parts || $store.state.licenseType.carinfo"></th> -->
                        <th class="backBlack" style="font-size:1.2em;background:#7aa5da;text-align:center;">{{lang.totals}}</th>
                        <!-- <th class="backGreen" style="font-size:1.2em;background:green;text-align:center;color:#fff">{{ $convertToComa(sumField('total')) }}</th> -->
                        <th class="backBlack" style="font-size:1.2em;background:blue !important;text-align:center;">{{ $convertToComa(sumField('ftotal'),$store.state.licenseType.moneyComma) }}</th>
                        <th class="backRed" style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ $convertToComa(sumField('vat'),$store.state.licenseType.moneyComma) }}</th>
                        <th class="backGreen" style="font-size:1.2em;background:#000 !important;text-align:center;">{{ $convertToComa(sumField('paid'),$store.state.licenseType.moneyComma) }}</th>
                        <th class="backBlack" style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ $convertToComa(sumField('cash'),$store.state.licenseType.moneyComma) }}</th>
                        <th class="backBlack" style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ $convertToComa(sumField('span'),$store.state.licenseType.moneyComma) }}</th>
                        <th class="backGold" style="font-size:1.2em;background:#7aa5da;text-align:center;">{{ $convertToComa(sumField('remain'),$store.state.licenseType.moneyComma) }}</th>
                        <th class="backEmpty" v-if="$store.state.licenseType.showAddedBy"></th>
                        <th class="backEmpty"></th>
                        <th class="backEmpty"></th>
                    </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">

          <v-container>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                value="15"
                :length="pageCount"
                @input="getNext(page)"
                color="#000"
              ></v-pagination>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-row>
    <div class="loadingPage" v-if="$store.state.showLoading">
      <v-progress-circular
          :width="3"
          color="green"
          indeterminate
      ></v-progress-circular>
      </div>
    <view-invoice :invoice="edinvoice" v-if="viewInvoice" />
    <addInvoice ref="addingInvoice" />
    <vue-snotify></vue-snotify>
  </div>
  <popViewInvocie ref="popviewinv" />
  
  <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import addInvoice from '@/components/addInvoice.vue'
// import addNewInvoice from '@/components/addNewInvoice.vue'
import ViewInvoice from '@/components/viewInvoice.vue'
import {SnotifyPosition} from 'vue-snotify';
import BreadCrumbs from '@/components/breadCrumbs.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import PopViewInvocie from '@/components/popViewInvocie.vue';
import tabsComp from '@/components/tabsComp.vue'
import ExportsPrint from '@/components/exports-print.vue';

  export default{
    name: 'Invoicelist',
    components: {
        addInvoice,
        // addNewInvoice,
        ViewInvoice,
        BreadCrumbs,
        Footer,
        HeaderPortrate,
        PopViewInvocie,
        tabsComp,
        ExportsPrint
    },
    data() {
      return {
        cpage: 0,
        plate_number: '',
        search: '',
        drow: false,
        page: 1,
        pageCount:0,
        edinvoice: 0,
        
        // sdate: new Date().toISOString().substring(0, 10),
        // edate: new Date().toISOString().substring(0, 10),
        mobile: '',
        excel_fields:{},
        tablerows: [
          
        ],
        ishome: true,
        viewInvoice: false,
        invoice_number: '',
        sd: {
          day: (new Date()).getDate(),
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
        },
        ed: {
          day: (new Date()).getDate(),
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
        }
      }
    },
    computed: {
      testDate: function(){
        let t = '';
        const da = new Date();
        t = da.getSeconds();
        console.log("this is test for time ",t);
        return t;
      },
      lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
      tablerowsF: function(){
        let t = [];
        for(let i=0;i<this.tablerows.length;i++){
          let s = {};
          Object.keys(this.tablerows[i]).forEach(key =>{
            if(key == 'total' || key == 'vat' || key == 'ftotal' || key == 'paid' || key == 'remain'){
              s[key] = parseFloat(this.tablerows[i][key]);
            }else{
              s[key] = this.tablerows[i][key]
            }
          })
          t.push(s)
        }
        return t;
      },
      sdate: function() {
        return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
      },
      edate: function() {
        return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
      },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
      items:function() {
          return {
            text: this.lang.invoices,
            disabled: true,
            href: '/invoicelist/',
          }
      },
      headers: function () {
        let x =  [
            { 
              text: "#", align: 'center',
              filterable: false, value: 'invoice_number',
              sortable: false,class: 'backBlack'
            },
            { 
              text: this.lang.customer_type, align: 'center',
              filterable: false, value: 'customer_type',
              sortable: false,class: 'backBlack'
            },
            {
              text: this.lang.invtype, align: 'center',
              filterable: false, value: 'invtype',
              sortable: false,class: 'backBlack'
            },
            {
              text: this.lang.customer, align: 'center',
              filterable: false, value: 'full_name',
              sortable: false,class: 'backBlack'
            },
            {
              text: this.lang.mobile, align: 'center',
              filterable: true, value: 'mobile',
              sortable: false, class: 'backBlack'
            },
        ];
        if(this.$store.state.licenseType.cars || this.$store.state.licenseType.carinfo || this.$store.state.licenseType.showPlate){
          x.push({
              text: this.lang.plate, align: 'center',
              filterable: true, value: 'plate_number',
              sortable: false,class: 'backBlack'
            });
            x.push({
                text: this.lang.car_model, align: 'center',
                filterable: false, value: 'model',
                sortable: false,class: 'backBlack'
                });
        // }
        // else if(this.$store.state.licenseType.parts){
        //   x.push({
        //       text: this.lang.model, align: 'center',
        //       filterable: false, value: 'model',
        //       sortable: false,class: 'backBlack'
        //       });
        }


          x.push({
            text: this.lang.inv_date, align: 'center',
            filterable: false, value: 'inv_date',
            sortable: false,class: 'backBlack'
          })
              ,
          // x.push({
          //     text: this.lang.total, align: 'center',
          //     filterable: false, value: 'total',
          //     sortable: false,class: 'backGreen'
          //   })
          x.push({
              text: this.lang.fftotal, align: 'center',
              filterable: false, value: 'ftotal',
              sortable: false,class: 'backBlue'
            })
          x.push({
              text: this.lang.vat, align: 'center',
              filterable: false, value: 'vat',
              sortable: false,class: 'backRed'
            });
          x.push({
              text: this.lang.paid, align: 'center',
              filterable: true, value: 'paid',
              sortable: false,class: 'backBlack'
            })
        x.push({
          text: this.lang.cash, align: 'center',
          filterable: true, value: 'cash',
          sortable: false,class: 'backBlack'
        })
        x.push({
          text: this.lang.span, align: 'center',
          filterable: true, value: 'span',
          sortable: false,class: 'backBlack'
        })
        x.push({
            text: this.lang.remain, align: 'center',
            filterable: true, value: 'remain',
            sortable: false,class: 'backGold'
          })
        if(this.$store.state.group_id == 1 && this.$store.state.licenseType.showAddedBy){
          x.push({
            text: this.lang.added_by, align: 'center', filterable: true,
            value: '_status', sortable: false,   class: 'backBlack'   
          });
        }
        x.push({
            text: this.lang.status, align: 'center', filterable: true,
            value: '_status', sortable: false,class: 'backBlack'
          })
        x.push({
            text: this.lang.action, align: 'center',
            filterable: false, value: '',
            sortable: false,class: 'backBlack'
          });
        
        return x;
      },
      tabs: function(){
        
        const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
           
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                // console.log("value",license);
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                      if(alicense[value.perms[0]] || value.perms[0] == 'any')
                          t.push(value)
                    }
                }
            })
        
            return t;
      }
    },
    created() {
      this.getStartDate();
      this.getCurrentCards();
      // setInterval(() => {this.getCurrentCards();},3000);
      this.getExcelHead();
      // alert((new Date()).getM)
    },
    methods: {
      // $convertToComa(num){
      //       // console.log(this.$store.state.licenseType.moneyComma);
      //     if(this.$store.state.licenseType.moneyComma){
      //         return Number(num).toLocaleString()
      //     }else{
      //         return num
      //     }
      // },
      addInvoiceEd(){
        this.$refs.addingInvoice.reset();
      },
      getStartDate(){
        let d = (new Date()).getDate();
        let m = (new Date()).getMonth() + 1;
        let y = (new Date()).getFullYear();
        if(parseInt(d) < 30){
          d = parseInt(30) - parseInt(d);
          if(parseInt(m) == 1){
            m = 12
            y = parseInt(y) - 1
          }
          else if(parseInt(m) > 1){
            m = parseInt(m) - 1;
          }
        }else if(parseInt(d) == 30){
          d = 1;
        }else{
          d = parseInt(d) - parseInt(30);
        }
        //-- start date today
        // this.sd.day = (new Date()).getDate();
        // this.sd.month = (new Date()).getMonth() + 1;
        this.sd.day = 1;
        this.sd.month = 1;
        this.sd.year = y;
      },
      preparedata(){
        let exdata = {
            list: "invoiceList",
            title: this.lang.invoices,
            data: [],
        }
        for(let i=0;i<this.tablerowsF.length;i++){
            exdata.data.push(this.tablerowsF[i]);
        }
        // console.log(exdata);
        this.$refs.exportbar.exportdata = exdata;
        this.$refs.exportbar.sdate = this.sdate;
        this.$refs.exportbar.edate = this.edate;
        
        this.$refs.exportbar.exporttitle = "";
        this.$refs.exportbar.palte_number = '';
        this.$refs.exportbar.contact_number = '';
      },
      cancelInvocie(item)
      {
          const invID = item.id;
          this.$snotify.confirm('هل أنت متاكد بانك تريد الغاء هذه الفاتورة؟ لا يمكن التراجع عن هذا االاجراء', 'تحذير', {
              timeout: 100000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              position: SnotifyPosition.centerCenter,
              buttons: [
                  {
                      text: 'متأكد', 
                      action: (toast) => {
                          this.doCancel(invID)
                          this.$snotify.remove(toast.id); 
                      },
                      bold: false
                  },
                  {
                      text: 'اغلاق', 
                      action: (toast) => {
                          this.$snotify.remove(toast.id); 
                      } 
                  },
              ]
          })
          if(!this.doitrelay){
              return false;
          }
      },
      doCancel(invID){
        if (this.$snotify.notifications.length > 0) {
            this.$snotify.notifications.forEach(notification => {
                this.$snotify.remove(notification.id)
            });
        }
        this.$snotify.error('يرجى اختيار سبب الالغاء'  , {
              timeout: 1000000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              
              position: SnotifyPosition.centerCenter,
              buttons: [
                  {
                      text: 'خطأ في الادخال', 
                      action: (toast) => {
                          this.doCancelIt(1,invID)
                          this.$snotify.remove(toast.id); 
                      },
                      bold: false
                  },
                  {
                      text: 'سوء الخدمة', 
                      action: (toast) => {
                          this.doCancelIt(2,invID)
                          this.$snotify.remove(toast.id); 
                      },
                      bold: false
                  },
                  {
                      text: 'السعر غير مناسب ', 
                      action: (toast) => {
                          this.doCancelIt(3,invID)
                          this.$snotify.remove(toast.id); 
                      },
                      bold: false
                  },
                  {
                      text: 'الغاء الامر', 
                      action: (toast) => {
                          this.$snotify.remove(toast.id); 
                      } 
                  },
              ]
          })
      },
      doCancelIt(id,invID){
            const post = new FormData();
            let cansel_reason = 'خطا في الادخال';
            if(id == 2){
              cansel_reason = 'سوء الخدمة';
            }
            else if(id == 3){
              cansel_reason = 'السعر غير مناسب';
            }
            post.append('type', 'cancelInvo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',invID)
            post.append('data[cancel_reson]',cansel_reason)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                this.$snotify.success('تم ارجاع الفاتورة بنجاح'  , {
                  timeout: 4000,
                  showProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                  position: SnotifyPosition.centerCenter,
                });
                this.getCurrentCards();
            })
      },
      getInvo(item){
          this.$refs.popviewinv.invid = item.id;
          this.$refs.popviewinv.invoice = item;
          this.$refs.popviewinv.getInvoice();
          this.$refs.popviewinv.getInvoiceAdd();
          // this.$router.push({path: '/view-invoice/'+item.id})
      },
      revokit(item)
      {
          if (this.$snotify.notifications.length > 0) {
              this.$snotify.notifications.forEach(notification => {
                  this.$snotify.remove(notification.id)
              });
          }
          this.$snotify.error('هل أنت متأكد بانك تريد الغاء الفاتورة؟', 'تحذير', {
                timeout: 6000,
                showProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                buttons: [
                    {
                        text: 'متأكد', 
                        action: (toast) => {
                            this.cancelConfirmed();
                            this.$snotify.remove(toast.id); 
                        },
                        bold: false
                    },
                    {
                        text: 'اغلاق', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                        } 
                    },
                ]
            })
      },
      printIt(item){
          window.open('../api/print.php?invid='+item.id,"_blank");
      },
      getExcelHead(){
        const head = this.headers;
        // for(var i=0;i< head.length;i++){
        //   const x = head[i].text;
        //   const y = head[i].value;
        //   this.excel_fields[x] = y;
        //   // console.log("xx",this.excel_fields[x]);
        // }
      },
      gotoDaily(){
        this.$router.push({path: '/reports/daily-reports'})
      },
      PrintPage(){
        window.print();
      },
      sumField(key) {

        let x = this.tablerows.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
        x = +x *100;
        x = (Math.round(x)/100);
        // x = Number(x).toLocaleString()
        if(x != 0) return x;
        else return 0;
      },
      ViewItem(item){
        
        if(item.cardid == null || typeof item.cardid === 'undefined'){
          return false;
        }else{
          this.$router.push({ path: '/view-card/'+item.cardid });
        }
      },
      getNext(page){
        this.cpage = parseInt(page) - 1;
        this.getCurrentCards();
      },
      getCurrentCards(){
        const ds = new Date(this.sdate);
        const de = new Date(this.edate);
        if(ds.getTime() > de.getTime()){
            let message = "يجب ان يكون تاريخ البدء اقل من او يساوي تاريخ الانتهاء";
            this.$snotify.error(message, 'تنبيه', {
                timeout: 1000000,
                showProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                position: SnotifyPosition.centerCenter,
                buttons: [
                    {
                        text: this.lang.close, 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                        } 
                    },
                ]
            });
            this.invoice.payment.cash = this.full_ftotal
            if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
            return false;
        }
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        let post = new FormData();
        post.append('type','getAllInvoices');
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        post.append('auth',cook);
        let data = {
          datatype: 'all',
          sdate: this.sdate,
          edate: this.edate,
          mobile: this.mobile,
          plate_number: this.plate_number,
          invoice_number: this.invoice_number,
        };
        post.append('data[datatype]',data.datatype);
        post.append('data[sdate]',data.sdate);
        post.append('data[cpage]',this.cpage);
        post.append('data[edate]',data.edate);
        post.append('data[mobile]',data.mobile);
        post.append('data[plate_number]',data.plate_number);
        post.append('data[invoice_number]',data.invoice_number);
        // this.tablerows = [];
        this.$store.state.showLoading = true;
        axios.post(
          this.$store.state.SAMCOTEC.r_path,
          post
        ).then((response) => {
          this.$store.state.showLoading = false;
          const res = response.data;
          // console.log("الفواتير",res);
          if(typeof res.error !== 'undefined' && res.error != null && typeof res.error.number !== 'undefined'
              && res.error.number != null && res.error.number == 200){
                this.tablerows = res.results.data;
                // let p = parseInt(parseInt(res.results.data.length)/10);
                // this.page = p+1
                this.pageCount = parseInt(parseInt(res.results.allres) / 15) + 1
                this.page = parseInt(this.cpage) + 1
              }
        })
      },
      ExportToExcel(){
        //
      },
    }
  }
</script>
<style>
.backBlue{
  background: blue !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.backGold{
  background: gold !important;
  color: #000 !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.viewBTN{
  font-size:.8rem;
  background:blue !important;
  width:100%;
  color:#fff !important;
  height:25px !important;
  margin-left:5px;
  text-align:center;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
  background:blue !important;
  width:100%;color:#fff !important;
}
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff50;
  z-index: 100000;
}
</style>
